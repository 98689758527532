var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataItem.inEdit
    ? _c("td", { class: _vm.className }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.dataItem.contact
                ? _vm.dataItem.contact.name +
                    " " +
                    _vm.dataItem.contact.last_name
                : ""
            ) +
            " "
        ),
      ])
    : _c(
        "td",
        [
          _c("v-autocomplete", {
            attrs: {
              items: _vm.contacts,
              outlined: "",
              "item-text": "full_name",
              "item-value": "id",
              dense: "",
              label: "Select Contact",
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }